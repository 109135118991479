import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TTEHeaderImage from "../images/tte-header.png";

import "./tte.css";

const Privacy = () => {

  return (
    <Layout title="ChatForge | UK Chatbot Services">
      <SEO title="TTE" />
      <article class="tte section">
        <img src={TTEHeaderImage}></img>
        <h1>Join us and our partners at TTE 2020 on the 26th & 27th of February</h1>
        <p>We’re delighted to be coming to Travel Technology Europe in partnership with MHR and powered by talksuite. We’ll be demonstrating our first line support automation and experience solution, our rostering optimisation engine and showing off our best-of-breed digital assistants.</p>
        <p>So, who are chatforge? We’re a group of people who not only have years of experience in designing and building digital assistant solutions but we are deeply passionate about the power of using conversational interfaces in the right way.</p>
        <p>We are a proud partner of <a href="http://talksuite.tools" target="popup">talksuite</a> which we know to be an incredibly versatile, enterprise-grade chatbot building platform. Because of this, we can offer a unique combination of conversational design, development speed, security, stability and, ultimately, value for money.</p>
        <p>If you want to have a sneak peak of what we’ll be showing at TTE, look around our site or download the “chatforge” app from your App Store. We’re looking forward to seeing you at TTE! </p>
        <iframe class="bot-container" src="https://chatforgewebaccount.blob.core.windows.net/webchatwidget/tte-embed.html" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>Travel Technology Europe (TTE) is Europe’s dedicated event for travel technology and is in it’s 17th year of creating a renowned space for learning, networking and collaboration. If you want to learn more you can go to their site or watch their video below.</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/wIxCl71C6uo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a class="button" href="https://traveltechnologyeurope.com">Visit traveltechnologyeurope.com</a>
      </article>
    </Layout>
  )
}

export default Privacy;