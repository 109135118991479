import React from "react"
import Header from "./header"
import Footer from "./footer"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div class="blogLayout">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
